export class CustomerSatisfactionInputModification {
  constructor() {
    this._aside = document.querySelector('aside.c-customer-satisfaction-input');
    if (this._aside) {
      this._form = this._aside.querySelector('form');
      this._surveyLink = this._form.querySelector('[data-customer-satisfaction-input="survey-link"]');
      this._formRadios = Array.from(this._form.querySelectorAll('[data-customer-satisfaction-input="radio"]'));
      this._submitButton = this._form.querySelector('button[type="submit"]');
      this._setSurveyLinkHref();
      this.attachEventListeners();
    }
  }
  attachEventListeners() {
    this._submitButton.addEventListener("click", this.updateSurveyLink.bind(this));
  }

  updateSurveyLink() {
    const selectedElement = this._formRadios.find(element => element.checked);
    if (selectedElement) {
      const ratingValue = selectedElement.value;
      this._surveyLink.href = this._surveyLink.href + `&rating=${ratingValue}`;
    }
  }
  _setSurveyLinkHref() {
    if (this._surveyLink && this._surveyLink.href) {
      const hrefComponents = this._surveyLink.href.split('?');
      if (hrefComponents.length > 2) {
        const hrefLocation = hrefComponents.pop();
        this._surveyLink.href = hrefComponents.join('?') + `&${hrefLocation}`;
      }
    }
  }
}
