import * as Sentry from "@sentry/browser";
const sentryUtility = {};

Sentry.init({dsn:"https://db43ee314fcc4040bb900b8e1e310f2c@o173313.ingest.sentry.io/6473988"});

sentryUtility.sendToSentry = (errorMsg, url, lineNumber, column, errorObj, isLive) => {
  const err = errorObj ? errorObj : sentryUtility.createCustomErr(errorMsg, url, lineNumber, column);
  if (isLive) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.log('Sending error to sentry failed: ', err);
    }
  } else {
    console.log(err);
  }
};

sentryUtility.createCustomErr = (errorMsg, url, lineNumber, column) => {
  return `${errorMsg} Line: ${lineNumber} Column: ${column}`;
};

export default sentryUtility;
