import $ from 'jquery';

export class feedbackFileUpload {
  constructor() {
    this.counter = 0;
    this.uploadFileButton = $("#uploadFeedbackFileForAuthorButton");
    this.attachEventListners();
    this.invalidFileAttachmentExtension = ["ade", "adp", "app", "application", "asa", "asp", "bas", "bat", "cer",
      "chm", "cla", "class", "cmd", "cnt", "com", "cpl", "crt", "csh", "der", "dll", "docm",
      "dotm", "exe", "fxp", "gadget", "grp", "hlp", "hpj", "hta", "htr", "inf", "ins", "isp",
      "its", "jar", "js", "jse", "ksh", "lnk", "mad", "maf", "mag", "mam", "maq", "mar", "mas",
      "mat", "mau", "mav", "maw", "mcf", "mda", "mdb", "mde", "mdt", "mdw", "mdz", "mht", "mhtm",
      "mhtml", "msc", "msh", "msh1", "msh1xml", "msh2", "msh2xml", "mshxml", "msi", "msp", "mst",
      "ocx", "ops", "osd", "pcd", "pif", "pl", "plg", "potm", "ppam", "ppsm", "pptm", "prf",
      "prg", "ps1", "ps1xml", "ps2", "ps2xml", "psc1", "psc2", "pst", "reg", "scf", "scr", "sct",
      "shb", "shs", "sldm", "tmp", "url", "vb", "vbe", "vbp", "vbs", "vbx", "vsmacros", "vss",
      "vst", "vsw", "ws", "wsc", "wsf", "wsh", "xbap", "xlam", "xlsm", "xltm", "xnk", "xsl"];
    this.validFileAttachmentExtension = ["doc", "docx", "pdf"];
  }

  attachEventListners() {
    this.uploadFileButton.on("change", this.addUploadFeedbackFileDetailOnUI.bind(this));
    $("body").on("click", ".delete_uploaded_file_btn", this.deleteUploadedFeedbackFile.bind(this));
  }

  async uploadFile(event, formData, _csrf, inputFileUniqueId) {
    const self = this;
    event.preventDefault();
    const saveNextBtn = $(`#save_btn_next`);
    const accessId = document.getElementById("access_id").value;
    const url = `/upload/${accessId}?_csrf=${_csrf}`;
    const removeBtn = $(`.${inputFileUniqueId}_unique_id_remove_btn`);
    saveNextBtn.prop("disabled", true);
    $.ajax({
      url: url,
      type: 'POST',
      data: formData,
      cache: false,
      processData: false,
      contentType: false,
      dataType: "json",
      enctype: 'multipart/form-data'
    })
      .done(function (res) {
        $(`.${inputFileUniqueId}_success_file_upload`).removeClass("u-js-hide");
        $(`.${inputFileUniqueId}_error_logo`).addClass("u-js-hide");
        $(`.${inputFileUniqueId}_loader_file_upload`).addClass("u-js-hide");
        if (res.error_message) {
          self.showErrorMessage(inputFileUniqueId, res.error_message);
        }
        removeBtn.removeClass("u-js-hide");
        removeBtn.attr("formaction", `/delete-file/${res.review_attachment_id}`);
        saveNextBtn.prop("disabled", false);
      })
      .fail(function (error) {
        const errorMessage = error.error_message || "We had a problem uploading your file. Please try uploading your file again.";
        self.showErrorMessage(inputFileUniqueId, errorMessage);
        removeBtn.removeClass("u-js-hide");
        removeBtn.attr("formaction", `/delete-file/${error.review_attachment_id}`);
        saveNextBtn.prop("disabled", false);
      });
  }

  deleteFile(event) {
    event.preventDefault();
    const isFileErrored = $(event.currentTarget).hasClass('remove-errored-file');
    if (isFileErrored) {
      const target = event.target;
      if (target && target.parentNode && target.parentNode.parentNode) {
        target.parentNode.parentNode.removeChild(target.parentNode);
      }
      document.getElementById('uploadFeedbackFileForAuthorButton').value = "";
    } else {
      const url = $(event.currentTarget).attr("formAction");
      const targetParentElement = $(event.target.parentElement);
      const _csrf = $('#_csrf').val();
      $.ajax({
        url: url,
        type: 'POST',
        data: {
          _csrf
        },

      })
        .done(function (res) {
          const target = event.target;
          if (target && target.parentNode && target.parentNode.parentNode) {
            target.parentNode.parentNode.removeChild(target.parentNode);
          }
          document.getElementById('uploadFeedbackFileForAuthorButton').value = "";
        })
        .fail(function (error) {
          targetParentElement.find(".success_file_upload").addClass("u-js-hide no-js-hide");
          targetParentElement.find(".error_msg").removeClass("u-js-hide no-js-hide");
          targetParentElement.find(".error_logo").removeClass("u-js-hide no-js-hide");
          targetParentElement.find(".error_msg").html("We had a problem removing your file. Please try removing your file again.");
        });
    }
  }

  async addUploadFeedbackFileDetailOnUI(event) {
    const inputFeedbackFile = document.getElementById("uploadFeedbackFileForAuthorButton");
    const _csrf = $('#_csrf').val();
    const uploadFeedbackFileAPIArray = [];
    for (const file of inputFeedbackFile.files) {
      this.counter++;
      const formData = new FormData();
      const li = this.generateListItemForNewFile(this.counter, file);
      document.getElementsByClassName("upload_feedback_file_list")[0].appendChild(li);
      formData.append("uploadFeedbackFileForAuthorButton", file);
      const isFileAttachmentValidFlags = this.checkFileAttachmentForValidation(file);
      if (isFileAttachmentValidFlags.isValidFileAttachment) {
        uploadFeedbackFileAPIArray.push(this.uploadFile(event, formData, _csrf, this.counter));
        await Promise.all(uploadFeedbackFileAPIArray);
      } else {
        let errorMessage = '';
        let helpLinkHTML = '';
        if (isFileAttachmentValidFlags.fileExtensionError) {
          errorMessage= window.anonymizationFlag ? "We do not support this file type. Please upload a DOC, DOCX or PDF file and try again." : 'We cannot support this file type. Please use a supported file format and try uploading your file again.';
          helpLinkHTML = window.anonymizationFlag ? `` : `
            <input type='checkbox' id='check_${this.counter}' class='toggleCheck' />
            <label class='toggleButton' for='check_${this.counter}'> What file formats can I upload? </label>
            <section class='toggleContent'>
                To make sure others are able to open and use your review files, we recommend that you upload them in the following formats:
                <ul>
                    <li>
                        <b>Text, slides and tables: </b>
                        docx, doc, pdf, txt, ppt, pptx, xls, xlsx, csv, zip
                    </li>
                    <li>
                        <b>Images and media: </b>
                        jpg, jpeg, png, gif, tif, tiff, eps, mp4, avi, wmv
                    </li>
                </ul>
            </section>`;
        } else if (isFileAttachmentValidFlags.fileSizeError) {
          errorMessage = window.anonymizationFlag ? "We don’t accept files larger than 500MB. Please make your file smaller and try again." : "We don’t accept files larger than 2GB. Please make your file smaller and try again.";
        } else if (isFileAttachmentValidFlags.latexError) {
          errorMessage = "We don’t accept .tex or .ltx files. Please convert your file to .pdf format and upload again.";
        }
        this.showErrorMessage(this.counter, errorMessage, helpLinkHTML);
        const removeBtn = $(`.${this.counter}_unique_id_remove_btn`);
        removeBtn.removeClass("u-js-hide");
        removeBtn.addClass("remove-errored-file");
      }
    }
  }

  deleteUploadedFeedbackFile(event) {
    this.deleteFile(event);
  }

  generateListItemForNewFile(counter, file) {
    const li = document.createElement("li");
    li.className = `${counter}_list_id`;
    li.innerHTML = `<div>
                        <img class="pr-2 icon ${counter}_loader_file_upload" width="35" src="/images/icon-loader-file-upload.svg" alt="loader">
                        <img class="pr-2 icon ${counter}_error_logo error_logo u-js-hide" width="35" src="/images/Fail.svg" alt="Failed file">
                        <img class="pr-2 icon ${counter}_success_file_upload success_file_upload u-js-hide" width="35" src="/images/page.svg" alt="uploaded file">
                        <label class="label_text_default_cursor file_name_label_text">${file.name}</label><input class="delete_uploaded_file_btn ${counter}_unique_id_remove_btn u-js-hide" type="submit" value="Remove" formaction=""></label>
                        <div class="label_text_default_cursor">
                            <label class="${counter}_error_msg_feedback_file_upload error_msg label_text_default_cursor u-js-hide"></label>
                        </div>
                        <div class='helpLinkContainer_${counter}'></div>
                        <hr>
                     </div>   
                        `;
    return li;
  }

  showErrorMessage(counter, errorMessage, helpLinkHTML) {
    $(`.${counter}_success_file_upload`).addClass("u-js-hide");
    $(`.${counter}_error_logo`).removeClass("u-js-hide");
    $(`.${counter}_loader_file_upload`).addClass("u-js-hide");
    $(`.${counter}_error_msg_feedback_file_upload`).html(errorMessage);
    if (helpLinkHTML) {
      $(`.helpLinkContainer_${counter}`).html(helpLinkHTML);
    }
    $(`.${counter}_error_msg_feedback_file_upload`).removeClass("u-js-hide");
  }

  checkFileAttachmentForValidation(file) {
    const fileName = file.name;
    const fileExtension = fileName.split('.')[fileName.split('.').length - 1];
    const fileSizeInGB = file.size / 1000000000;
    let fileExtensionError = false;
    if (window.anonymizationFlag) {
      fileExtensionError = !this.validFileAttachmentExtension.includes(fileExtension);
    } else {
      fileExtensionError = this.invalidFileAttachmentExtension.includes(fileExtension);
    }
    const latexError = fileExtension === 'tex' || fileExtension === 'ltx';
    const fileSizeError = window.anonymizationFlag ? fileSizeInGB > 0.5 : fileSizeInGB > 2;
    const isValidFileAttachment = fileExtensionError || latexError || fileSizeError ? false : true;
    return {fileExtensionError, fileSizeError, latexError, isValidFileAttachment};
  }
}
