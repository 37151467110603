import $ from 'jquery';

export class CopyTextOnClick {
  constructor() {
    this.textToCopy = ".coupon-code-text";
    this.copyStatusMessage = ".c-reviewer-detail__coupon-block__copy-status-section";
    this.attachEventListners();
  }

  attachEventListners() {
    $("body").on("click", this.textToCopy, this.copyToClipboard.bind(this));
  }

  copyToClipboard(event) {
    const tempInput = document.createElement('input');
    tempInput.value = $(event.target).text();
    document.body.appendChild(tempInput);
    tempInput.select();
    $(this.copyStatusMessage).removeClass('copy-status-hide');
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setTimeout(() => {
      $(this.copyStatusMessage).addClass('copy-status-hide');
    }, 3000);
  }
}
