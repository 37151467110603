import $ from 'jquery';
import {FeedbackForm} from "./components/feedbackForm";
import {OptedReviewers} from "./components/optedReviewers";
import {AutoSave} from "./components/autosave";
import {Modal} from "./components/modal";
import {ReviewHeader} from "./components/reviewHeader";
import {reviewInvitation} from "./components/reviewInvitation";
import {reviewDecline} from "./components/reviewDecline";
import {feedbackFileUpload} from "./components/feedbackFileUpload";
import {CopyTextOnClick} from "./components/copyTextOnClick";
import {ThankYou} from "./components/thankyou";
import downloadZipAll from './components/download-zip-all';
import userDetails from '../../../node_modules/@springernature/springernature-user-details/js';
import {ToastMessage} from "./components/ToastMessage";
import sentryUtility from "./sentry";
import autoresize from "autosize";
import {amplitudeTracking} from "./amplitudeTracking";
import {customerSatisfactionInput} from '../../../node_modules/@springernature/global-customer-satisfaction-input/js/index';
import {CustomerSatisfactionInputModification} from './components/customer-satisfaction-input-modification';


const start = () => {
  autoresize($(".txt"));
  window.review = {};
  window.review.isAutoSaveAPIRunning = false;
  window.review.isError = $("#isSizeExceed").val() === "true";
  window.review.errorMsg = $("#isSizeExceed").val() === "true" ? "Content exceeds 500kb. Please contact our support team." : "";
  window.RF = window.RF || {};
  new FeedbackForm();
  new OptedReviewers();
  customerSatisfactionInput();
  new CustomerSatisfactionInputModification();
  window.loadThankYouJs && new ThankYou();
  new Modal();
  new reviewInvitation();
  new reviewDecline();
  new feedbackFileUpload();
  new CopyTextOnClick();
  downloadZipAll();
  userDetails();
  // eslint-disable-next-line no-undef
  if (RF.context.displayToastMessage) {
    const toastOptions = {
      message: "Your recommendation was sent successfully.",
      duration: 5
    };
    const toast = new ToastMessage(toastOptions);
    toast.show();
  }
  const autoSaveObject = new AutoSave();
  autoSaveObject.updateLastSaveDateOnUI();
  window.onbeforeunload = function () {
    if (!window.review.formChanged) {
      return undefined;
    }
    return 'It looks like you have been editing something. ' +
        'If you leave before saving, your changes will be lost.'; // Gecko + Webkit, Safari, Chrome etc.
  };
  new ReviewHeader();

  $(document).on("loader", ()=>{
    $(".loader_container").toggleClass('hide');
  });
};

if (/complete|interactive/.test(document.readyState)) {
  start();
} else {
  document.addEventListener('DOMContentLoaded', start);
}

window.jQuery = $;
$(document).ready(() => {
  if ((window.amplitudeTrackingTestFlag && window.isStage) || window.isLive) {
    new amplitudeTracking();
  }
  window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
    const isLive = $('body').attr('data-live') === "";
    sentryUtility.sendToSentry(errorMsg, url, lineNumber, column, errorObj, isLive);
  };
});
