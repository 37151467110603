import $ from 'jquery';
import autoresize from "autosize";

export class AutoSave {
  constructor() {
    this.saveTimer = 5000;
    window.review.formChanged = false;
    setInterval(() => {
      if (!window.review.isAutoSaveAPIRunning) {
        if (window.review.formChanged) {
          window.review.formChanged = false;
          this.sendData(window.review.formTarget);
        }
        if (!window.review.isAutoSaveAPIRunning) {
          this.updateLastSaveDateOnUI();
        }
      }
    }, this.saveTimer);

    $('body').on('change keydown', ".editorFeedbackForm, .authorFeedbackForm, .reviewerConsentFeedbackForm", (event) => {
      window.review.formChanged = true;
      window.review.formTarget = event.currentTarget.className;
      autoresize($(".txt"));
    });
  }


  sendData(formTarget) {
    $.ajax({
      type: "POST",
      url: $(`.${formTarget}`).attr("action"),
      data: $(`.${formTarget}`).serialize(), // serializes the form's elements.
      beforeSend: () => {
        window.review.isAutoSaveAPIRunning = true;
        window.review.formChanged = false;
        $(".autosave_status_success").removeClass('hidden');
        $(".autosave_status").removeClass('hidden').removeClass('fail').html("Saving...");
        $(".autosave_status_success > img").addClass("hidden");
      }
    })
      .done(function () {
        window.review.isAutoSaveAPIRunning = false;
        window.review.isError = false;
        window.review.lastSaveDate = new Date();
      })
      .fail(function (error) {
        let msg = '';
        if (error.status === 409) {
          msg = error.responseText;
        } else if (error.status === 401) {
          msg = error.responseText;
        } else if (error.status === 413) {
          msg = 'Content exceeds 500kb.';
        } else if (error.status === 403) {
          msg = 'Sorry, something went wrong.';
        } else {
          msg = 'Something went wrong. We are not currently able to save your changes.';
        }
        window.review.isAutoSaveAPIRunning = false;
        window.review.isError = true;
        window.review.errorMsg = msg;
      });
  }

  updateLastSaveDateOnUI() {
    const dateFromInputTag = $(".last_submitted_date > input").val();
    let currentLastSubmittedDate;
    if (dateFromInputTag !== 'null') {
      currentLastSubmittedDate = new Date($(".last_submitted_date > input").val());
    }
    if (window.review.lastSaveDate) {
      currentLastSubmittedDate = window.review.lastSaveDate;
    }
    if (currentLastSubmittedDate && !window.review.isError) {
      const dateDifference = Math.abs(new Date() - currentLastSubmittedDate) / 1000;
      const days = Math.floor(dateDifference / 86400);
      const hours = Math.floor(dateDifference / 3600) % 24;
      const minutes = Math.floor(dateDifference / 60) % 60;
      const seconds = Math.floor(dateDifference) % 60;
      const lastSavedMsg = "Last saved " + (days !== 0 ? (days.toString() + this.calculateFormOfTheWord(days, 'day')) : "") + " " + (hours !== 0 ? (hours.toString() + this.calculateFormOfTheWord(hours, 'hour')) : "") + " " + (minutes !== 0 ? (minutes.toString() + this.calculateFormOfTheWord(minutes, 'minute')) : "") + " " + seconds + " second(s) ago";
      $(".autosave_status").removeClass('fail').html(lastSavedMsg);
      $(".autosave_status_success").removeClass("hidden");
      $(".autosave_status_success > img").removeClass("hidden");
    } else if (window.review.isError) {
      $(".autosave_status_success").removeClass("hidden");
      $(".autosave_status_success > img").addClass("hidden");
      $(".autosave_status").html(window.review.errorMsg).addClass('fail');
    }
  }

  calculateFormOfTheWord(amount, word) {
    return amount > 1 ? " " + word + "s" : " " + word;
  }
}
