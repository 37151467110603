import $ from 'jquery';

export class Modal {
  constructor() {
    this.backgroundModal = $(".css_only_modal .modal");
    this.contentModal = $(".css_only_modal .modal .modal__content");
    this.attachEventListners();
  }
  attachEventListners() {
    this.backgroundModal.on("click", this.closeModalIfClickedOnBackground.bind(this));
    this.contentModal.on("click", this.preventClickOnContentModal.bind(this));
  }

  closeModalIfClickedOnBackground(e) {
    if ($(e.target).is(":visible")) {
      window.location.hash = '';
    }
  }

  preventClickOnContentModal(e) {
    e.stopPropagation();
  }
}

