import $ from 'jquery';
import {ToastMessage} from "./ToastMessage";

export class ThankYou {
  constructor() {
    this.wosConsentSubmit = $("#wosConsentSubmit");
    this.loader = $(".loader_container");
    this.formSubmittedMessageSection = $("#formSubmittedMessageSection");
    this.wosFeedBackForm = $("#wosFeedBackForm");
    this.attachEventListners();
  }

  attachEventListners() {
    this.wosConsentSubmit.on('click', this.submitWosFeedback.bind(this));
  }

  submitWosFeedback(event) {
    event.preventDefault();
    const vm = this;
    const action = event.target.form.action;
    const wosFeedbackRadios = [...document.getElementsByName("wosConsent")];
    const { yes, no } = {yes: wosFeedbackRadios[0].checked, no: wosFeedbackRadios[1].checked};
    let postObj = {};
    if (!yes && !no) {
      const toastOptions = {
        message: "You need to choose one of the options before submitting.",
        duration: 5
      };
      const toast = new ToastMessage(toastOptions);
      toast.show();
      return;
    } else {
      const csrfToken = $("#_csrf").val();
      postObj = { wosConsent: yes ? "yes" : "no", _csrf: csrfToken, wosConsentSubmit: "wosConsentSubmit"};
    }
    vm.loader.removeClass('hide');
    $.ajax({
      url: action,
      type: 'POST',
      data: postObj,
      dataType: 'html',
    })
      .done(function (res) {
        vm.wosFeedBackForm.addClass("hide");
        vm.formSubmittedMessageSection.removeClass("hide");
        vm.loader.addClass('hide');
      })
      .fail(function (error) {
        vm.loader.addClass('hide');
        const toastOptions = {
          message: "Sorry, something went wrong. Please try again.",
          duration: 5
        };
        const toast = new ToastMessage(toastOptions);
        toast.show();
      });
  }
}

