import $ from 'jquery';

export class reviewDecline {
  constructor() {
    this.addReviewer = $(".c-suggest-reviewers--add-reviewer");
    this.selectedDeclineReason = $("#declineReason");
    this.attachEventListners();
  }

  attachEventListners() {
    this.addReviewer.on("click", this.addReviewerPanel.bind(this));
    this.selectedDeclineReason.on("change", this.addDataTrackLableOnSubmitButton.bind(this));
  }

  addReviewerPanel(e) {
    e.preventDefault();
    const selector = 'data-component-suggested-reviewer';
    const lastRow = Array.from(document.querySelectorAll(`[${selector}]`)).pop();
    const index = Number(lastRow.getAttribute(selector)) + 1;
    const clone = lastRow.cloneNode(true);
    Array.from(clone.querySelectorAll('input, select')).forEach(child => {
      child.name = child.name.replace(/[\d]/g, index);
      child.value = '';
    });
    clone.setAttribute(selector, index);
    lastRow.insertAdjacentElement('afterend', clone);
    document.querySelector('[name="suggested_reviewers_length"]').value = index;
    window.history.pushState('', {}, `?reviewerLength=${index}`);
  }

  addDataTrackLableOnSubmitButton(e) {
    const declineReason = $("#declineReason option:checked").val();
    $(".form__submit").attr("data-track-label", declineReason);
  }
}
