export class ToastMessage {
  constructor(options = {}) {
    this.toast = document.querySelector('[data-component-toast]').cloneNode(true);
    this.type = options.type || 'info';
    this.message = options.message || (this.type === 'error' ? this.toast.getAttribute('data-error-message-default') : '');
    this.closeButton = this.toast.querySelector('[data-component="modal-close"]');
    this.duration = options.duration || false;
    this.showClassList = ['u-fade-in-down'];
    this.hideClassList = ['u-fade-out-up'];
    this.actions = {
      id: options.actionId,
      href: options.actionUrl,
      label: options.actionLabel || this.toast.getAttribute('data-error-actionbutton-default')
    };

    if (this.closeButton) {
      this.closeButton.addEventListener('click', this.destroy.bind(this));
    }
  }

  show() {
    this.toast.querySelector('.message').innerHTML = this.message;
    this.toast.setAttribute('data-type', this.type);
    this.toast.classList.add(this.type);
    document.body.appendChild(this.toast);
    this.toast.classList.add(this.showClassList);
    this.toast.classList.remove(this.hideClassList);
    this.toast.setAttribute('tabindex', '0');
    this.toast.focus();

    const durationMs = parseInt(this.duration, 10) * 1000;

    if (durationMs && durationMs > 0) {
      setTimeout(() => {
        this.destroy();
      }, durationMs);
    }

    if (this.actions.id) {
      const actionLinkHTML = `<a href="${this.actions.href}" id="${this.actions.id}" aria-label="${this.actions.label}">${this.actions.label}</a>`;
      this.toast.querySelector('.actions').innerHTML = actionLinkHTML;
    }

    return true;
  }

  destroy() {
    this.toast.classList.add(this.hideClassList);
    this.toast.classList.remove(this.showClassList);
    if (this.toast) {
      this.toast.addEventListener('animationend', () => {
        if (this.toast.parentNode) {
          this.toast.parentNode.removeChild(this.toast);
        }
      });
    }
  }
}

