import $ from 'jquery';

export class reviewInvitation {
  constructor() {
    this.radioButtonCheck = $(".form__radio");
    this.attachEventListners();
  }

  attachEventListners() {
    this.radioButtonCheck.on("click", this.addDataTrackLableOnContinueButton.bind(this));
    this.radioButtonCheck.on("change", this.enableContinueButton.bind(this));
  }

  addDataTrackLableOnContinueButton(e) {
    if (e.target.id === 'accept') {
      $(".inivitation-submit-button").attr("data-track-label", "review-invitation-accepted");
    } else {
      $(".inivitation-submit-button").attr("data-track-label", "review-invitation-declined");
    }
  }

  enableContinueButton(e) {
    if (e.currentTarget.checked) {
      $(".inivitation-submit-button").removeClass("disableButton");
    }
  }
}
