import $ from 'jquery';

export class OptedReviewers {
  constructor() {
    this.submitButtons = $("[name='submit']");
    this.attachEventListners();
  }

  attachEventListners() {
    this.submitButtons.on("click", this.submitName.bind(this));
  }

  submitName(event) {
    let givenName = $(event.target).parents('tr').find('#givenname').val();
    let familyName = $(event.target).parents('tr').find('#familyname').val();
    const _csrf = $('#_csrf').val();
    givenName = givenName.trim();
    familyName = familyName.trim();
    const email = $(event.target).attr('email');
    const url = $(event.target).attr('action');
    const year = $(event.target).attr('year');
    const month = $(event.target).attr('month');
    if (!givenName && !familyName) {
      alert('Please add at least one of Given Name or Family Name');
      return;
    }
    $.ajax({
      type: "POST",
      url: url,
      data: {
        given_name: givenName,
        family_name: familyName,
        email: email,
        year,
        month,
        _csrf
      }
    })
      .done(function () {
        window.location.reload();
      })
      .fail(function (error) {
        alert('Something went wrong');
      });
  }
}
