import $ from 'jquery';
import JsZip from 'jszip/dist/jszip';
import FileSaver from 'file-saver';
// import CssLoader from '../components/css-loader';
import {ToastMessage} from "./ToastMessage";

const downloadZipAll = async () => {
  const downloadAllButtons = document.querySelectorAll('[data-component-download-all]');
  if (window.fetch === undefined || downloadAllButtons.length === 0) {
    return;
  }
  for (const downloadAllButton of downloadAllButtons) {
    const identifier = downloadAllButton.dataset.componentDownloadAll;
    const downloadLinks = Array.from(document.querySelectorAll(`[data-download=${identifier}]`));
    const loaderContainer = $(".loader_container");
    const spinnerText = $('.spinner_text');
    const accessId = document.getElementById('access_id').value;

    if (downloadAllButton && downloadLinks) {
      downloadAllButton.classList.remove('u-hide');
      const manuscriptTitle = document.querySelector('[data-object="manuscript-title"]').textContent.slice(0, 25).trim();
      // const loader = new CssLoader();
      let error = false;
      const downloadables = downloadLinks.map(download => {
        const fileName = download.dataset.fileName.trim().split('.');
        const type = fileName.pop();
        const extractedIDs = download && download.href ? download.href.split("/submission-file/")[1].split("/"): ['', '', ''];
        return {submissionId: extractedIDs[0], msVersion: extractedIDs[1], docId:extractedIDs[2], name: fileName.join(''), type};
      });

      const errorHandler = () => {
        if (!error) {
          error = true;
          const errorToast = new ToastMessage({
            message: 'We had a problem downloading the files. Please try again, or <a href="mailto:snapp-support@springernature.com?subject=Reviewer form">contact support</a> to get access to the files.',
            type: 'error'
          });
          errorToast.show();
          if (typeof ga !== "undefined") {
            ga('send', 'event', 'reviewer-feedback', 'Download all files', `Error downloading zip file with accessId ${accessId} for journalId ${RF.context.journal_id}`); // eslint-disable-line no-undef
          }
        }
      };

      const download = file => {
        error = false;
        const uri = `${window.location.origin}/download/files/${accessId}`;
        const _csrf = document.getElementById('_csrf').value;
        const postObj = {
          _csrf,
          file
        };
        return fetch(uri, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postObj),
        })
          .then(async resp => {
            if (resp.ok) {
              const responseBlob = await resp.blob();
              if (responseBlob) {
                return {blob: responseBlob, name: file.name, type: file.type};
              }
            } else {
              throw new Error(`No response from fetch call while downloading files`);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-undef
            throw new Error(`${error} for accessId ${accessId} for journal ${RF.context.journal_id}`);
          });
      };

      const exportZip = async () => {
        try {
          // loader.add();
          spinnerText.text('Preparing your download');
          loaderContainer.removeClass('hide');
          const timeStart = performance.now();
          const downloadedfiles = await Promise.all(downloadables.map(file => download(file)));
          const results = downloadedfiles.some(file => file !== undefined);
          if (results) {
            const zip = new JsZip();
            for (const file of downloadedfiles) {
              if (file) {
                const {name, type, blob} = file;
                if (name && type && blob) {
                  zip.file(`${name}.${type}`, blob);
                }
              } else {
                errorHandler();
              }
            }

            zip.generateAsync({type: 'blob'}).then(zipFile => {
              const fileName = `${manuscriptTitle}.zip`;
              const timeEnd = performance.now();
              const duration = ((timeEnd - timeStart) / 1000).toFixed(1);
              if (typeof ga !== "undefined") {
                ga('send', 'event', 'reviewer-feedback', 'Download all files', `Zip file for journalId ${RF.context.journal_id} - accessId ${accessId} - ${duration}s`); // eslint-disable-line no-undef
              }
              // loader.remove();
              loaderContainer.addClass('hide');
              spinnerText.text('Loading');
              return FileSaver.saveAs(zipFile, fileName);
            });
          } else {
            errorHandler();
            // loader.remove();
            loaderContainer.addClass('hide');
            spinnerText.text('Loading');
          }
        } catch (error) {
          errorHandler();
          loaderContainer.addClass('hide');
          spinnerText.text('Loading');
        }
      };

      downloadAllButton.addEventListener('click', event => {
        event.preventDefault();
        exportZip();
      });
    }
  }
};

export default downloadZipAll;
