import $ from 'jquery';

export class amplitudeTracking {
  constructor() {
    this.editorialDecisionHasBeenMade = document.getElementById('editorialDecisionHasBeenMade');
    this.sufficientReviewersSecured = document.getElementById('sufficientReviewersSecured');
    this.submissionNoLongerAvailable = document.getElementById('submissionNoLongerAvailable');
    this.reviewerOptOutPageViewed = document.getElementById('reviewerOptOutFormPage');
    this.noLongerAvailableForReview = document.getElementById('noLongerAvailableForReview');
    this.confirmOptOutButton = '.c-opt-out__form__btn';
    this.reviewerDashboardV1Page = document.getElementById('reviewerDashboardV1');
    this.loginOrRegister = '#login_or_register';
    this.continueAsGuest = '#continue_as_guest';
    this.dashboardLinkThankYouPage = '.c-reviewer-dashboard-link--thank-you';
    this.dashboardLinkNoLongerAvailablePage = '.c-reviewer-dashboard-link--submission-no-longer-available-page';
    this.dashboardLinkEditorialDecisionMadePage = '.c-reviewer-dashboard-link--editorial-decision-made-page';
    this.dashboardLinkSecuredSufficientNumberPage = '.c-reviewer-dashboard-link--secured-sufficient-number';
    this.dashboardLinkInvitationAlreadyAcceptedPage = '.c-reviewer-dashboard-link--invitation-already-accepted-page';
    this.dashboardLinkInvitationAlreadyDeclinedPage = '.c-reviewer-dashboard-link--invitation-already-declined-page';
    this.dashboardLinkNoLongerAvailablePreAcceptancePage = '.c-reviewer-dashboard-link--submission-no-longer-available-pre-acceptance-page';
    this.dashboardLinkFeedbackAlreadySubmittedPage = '.c-reviewer-dashboard-link--feedback-already-submitted-page';
    this.dashboardLinkFurtherInformationSentPage = '.c-reviewer-dashboard-link--further-information-sent';
    if (window.sncc && window.sncc.consent('C02') && window.amplitude && document.URL.includes('year_end_review')) {
      this.amplitudeTrackingForYearEndReviewPageView();
    }
    if (window.sncc && window.sncc.consent('C02') && window.amplitude && document.URL.includes('login')) {
      this.amplitudeTrackingForLoginInterceptPageView();
    }
    this.attachEventListeners();
  }

  attachEventListeners() {
    if (this.editorialDecisionHasBeenMade) {
      this.amplitudeTrackingForEditorialDecisionMade();
    }
    if (this.sufficientReviewersSecured) {
      this.amplitudeTrackingForSufficientReviewersSecured();
    }
    if (this.submissionNoLongerAvailable) {
      this.amplitudeTrackingForSubmissionNoLongerAvailable();
    }
    if (this.reviewerOptOutPageViewed) {
      this.amplitudeTrackingForReviewerOptOutPageViewed();
      $('body').on("click", this.confirmOptOutButton, this.amplitudeTrackingForReviewerRequestedOptOut.bind(this));
    }
    if (this.noLongerAvailableForReview) {
      this.amplitudeTrackingForReviewNoLongerRequiredPageViewed();
    }
    if (this.reviewerDashboardV1Page) {
      this.amplitudeTrackingForReviewerDashboardV1PageViewed();
    }
    if (this.loginOrRegister) {
      $('body').on("click", this.loginOrRegister, this.amplitudeTrackingForLoginOrRegister.bind(this));
    }
    if (this.continueAsGuest) {
      $('body').on("click", this.continueAsGuest, this.amplitudeTrackingForContinueAsGuest.bind(this));
    }
    $('body').on("click", this.dashboardLinkEditorialDecisionMadePage, this.trackDashboardButtonClickFromDifferentPages.bind(this, "Reviewer Clicked On Dashboard Link From Decision Made Page"));

    $('body').on("click", this.dashboardLinkThankYouPage, this.trackDashboardButtonClickFromDifferentPages.bind(this, "Reviewer Clicked On Dashboard Link From Thank you page"));

    $('body').on("click", this.dashboardLinkNoLongerAvailablePage, this.trackDashboardButtonClickFromDifferentPages.bind(this, "Reviewer Clicked On Dashboard Link From Submission No Longer Available Page"));

    $('body').on("click", this.dashboardLinkSecuredSufficientNumberPage, this.trackDashboardButtonClickFromDifferentPages.bind(this, "Reviewer Clicked On Dashboard Link From Secured Number Of Reviewers Page"));

    $('body').on("click", this.dashboardLinkInvitationAlreadyAcceptedPage, this.trackDashboardButtonClickFromDifferentPages.bind(this, "Reviewer Clicked On Dashboard Link From Invitation Accepted Page"));

    $('body').on("click", this.dashboardLinkInvitationAlreadyDeclinedPage, this.trackDashboardButtonClickFromDifferentPagesWithInvitationId.bind(this, "Reviewer Clicked On Dashboard Link From Invitation Already Declined Page"));

    $('body').on("click", this.dashboardLinkNoLongerAvailablePreAcceptancePage, this.trackDashboardButtonClickFromDifferentPages.bind(this, "Reviewer Clicked On Dashboard Link From No Longer Available Pre Acceptance Decision Made"));

    $('body').on("click", this.dashboardLinkFeedbackAlreadySubmittedPage, this.trackDashboardButtonClickFromDifferentPagesWithReviewerFormId.bind(this, "Reviewer Clicked On Dashboard Link From Feedback Submitted Page"));

    $('body').on("click", this.dashboardLinkFurtherInformationSentPage, this.trackDashboardButtonClickFromDifferentPagesWithInvitationId.bind(this, "Reviewer Clicked On Dashboard Link From Post Decline Page"));
  }

  amplitudeTrackingForYearEndReviewPageView() {
    const eventProperties = {};
    window.amplitude.track("Year End Review Page Viewed", eventProperties);
  }

  amplitudeTrackingForEditorialDecisionMade() {
    const eventProperties = {};
    const journalId = $(location).attr('href').split("/")[4];
    const reviewerFormId = $(location).attr('href').split("/")[5];
    eventProperties.reviewerFormId = reviewerFormId;
    eventProperties.journalId = journalId;
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Editorial Decision Made Page Viewed", eventProperties);
    }
  }

  amplitudeTrackingForSufficientReviewersSecured() {
    const eventProperties = this.getSubmissionDetailsPropertiesForAmplitudeTracking();
    const invitationId = $(location).attr('href').split("/")[4];
    eventProperties.invitationId = invitationId;
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Sufficient Reviewers Secured Page Viewed", eventProperties);
    }
  }

  amplitudeTrackingForSubmissionNoLongerAvailable() {
    const eventProperties = this.getSubmissionDetailsPropertiesForAmplitudeTracking();
    const invitationId = $(location).attr('href').split("/")[4];
    eventProperties.invitationId = invitationId;
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Submission No Longer Available Page Viewed", eventProperties);
    }
  }

  amplitudeTrackingForReviewerOptOutPageViewed() {
    const eventProperties = this.getSubmissionDetailsPropertiesForAmplitudeTracking();
    eventProperties.reviewerEmail = window.submissionDetails.invitedReviewerEmail;
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Reviewer Opt Out Page Viewed", eventProperties);
    }
  }

  amplitudeTrackingForReviewerRequestedOptOut(event) {
    const eventProperties = this.getSubmissionDetailsPropertiesForAmplitudeTracking();
    eventProperties.reviewerEmail = window.submissionDetails.invitedReviewerEmail;
    const optOutReason = $('.c-opt-out__form__section__group__select option:selected').text();
    optOutReason === 'Please select a reason' ? eventProperties.optOutReason = "null" : eventProperties.optOutReason = optOutReason;
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Reviewer Requested Opt Out", eventProperties);
    }
  }

  amplitudeTrackingForReviewNoLongerRequiredPageViewed() {
    const eventProperties = this.getSubmissionDetailsPropertiesForAmplitudeTracking();
    eventProperties.reviewerFormId = $(location).attr('href').split("/")[5];
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Review No Longer Required Page Viewed", eventProperties);
    }
  }

  amplitudeTrackingForReviewerDashboardV1PageViewed() {
    const eventProperties = {};
    eventProperties.reviewerEmail = $('#account-nav-title').find('span')[1].innerText;
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Reviewer Dashboard 1.0 Page Viewed", eventProperties);
    }
  }

  amplitudeTrackingForLoginOrRegister() {
    const eventProperties = this.getEventPropertiesForLoginIntercept();
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Reviewer Clicked Login Or Register Button", eventProperties);
    }
  }

  amplitudeTrackingForContinueAsGuest() {
    const eventProperties = this.getEventPropertiesForLoginIntercept();
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track("Reviewer Clicked Continue As Guest", eventProperties);
    }
  }

  amplitudeTrackingForLoginInterceptPageView() {
    const eventProperties = this.getEventPropertiesForLoginIntercept();
    window.amplitude.track("Reviewer Landed on Login Intercept Page", eventProperties);
  }

  getEventPropertiesForLoginIntercept() {
    const eventProperties = {};
    eventProperties.submissionId = document.getElementById('submissionId').value;
    eventProperties.reviewerEmail = document.getElementById('reviewerInvitedEmail').value;
    return eventProperties;
  }

  getSubmissionDetailsPropertiesForAmplitudeTracking() {
    const eventProperties = {};
    if (window.submissionDetails) {
      eventProperties.journalId = window.submissionDetails.journalId;
      eventProperties.journalTitle = window.submissionDetails.journalTitle;
      eventProperties.submissionId = window.submissionDetails.submissionId;
      eventProperties.versionTag = window.submissionDetails && window.submissionDetails.revisionVersion ? window.submissionDetails.revisionVersion : '';
    }
    return eventProperties;
  }

  trackDashboardButtonClickFromDifferentPages(trackingLabel, event) {
    const eventProperties = {};
    eventProperties.submissionId = window.submissionDetails && window.submissionDetails.submissionId ? window.submissionDetails.submissionId : window.submissionId;
    eventProperties.reviewerEmail = window.reviewerEmail;
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track(trackingLabel, eventProperties);
    }
  }

  trackDashboardButtonClickFromDifferentPagesWithInvitationId(trackingLabel, event) {
    const eventProperties = {};
    eventProperties.invitationId = $(location).attr('href').split("/")[4];
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track(trackingLabel, eventProperties);
    }
  }

  trackDashboardButtonClickFromDifferentPagesWithReviewerFormId(trackingLabel, event) {
    const eventProperties = {};
    eventProperties.reviewerFormId = $(location).attr('href').split("/")[5];
    if (window.sncc && window.sncc.consent('C02') && window.amplitude) {
      window.amplitude.track(trackingLabel, eventProperties);
    }
  }
}


