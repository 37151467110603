import $ from 'jquery';

export class FeedbackForm {
  constructor() {
    this.downArrow = $(".download_expand_collapse .down_arrow");
    this.upArrow = $(".download_expand_collapse .up_arrow");
    this.fileText = $(".file_label");
    this.submitButton = $("button[type='submit']");
    this.radioButtonCheck = $(".form-check-input");
    this.optInCheckbox = $(".checkbox_for_opt_in");
    this.tprConsent = $(".reviewer-consent-question .form-check-input");
    this.givenName = $("#givenName");
    this.familyName = $("#familyName");

    if ($("#optInError").val() === "true") {
      $(".name_optIn_container").removeClass('js-hide');
      $("#submit_review_report").attr('disabled', 'disabled');
    }
    this.attachEventListners();
  }

  attachEventListners() {
    this.downArrow.on("click", this.toggleFilter);
    this.upArrow.on("click", this.toggleFilter);
    this.fileText.on("click", this.toggleFilter);

    this.downArrow.on("keypress", this.toggleFilterForEnter.bind(this));
    this.upArrow.on("keypress", this.toggleFilterForEnter.bind(this));
    this.fileText.on("keypress", this.toggleFilterForEnter.bind(this));

    this.radioButtonCheck.on("change", this.clearTextArea.bind(this));
    this.submitButton.on("click", this.markFormUnchanged.bind(this));
    this.optInCheckbox.on("click", this.toggleOptinNameSection.bind(this));
    this.givenName.on("change", this.enableSubmitButton.bind(this));
    this.familyName.on("change", this.enableSubmitButton.bind(this));
  }

  toggleFilterForEnter(event) {
    const keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode == '13') {
      this.toggleFilter();
    }
  }

  toggleFilter() {
    const downloadManuscript = $(".download-manuscript");
    $(downloadManuscript).toggle(500);
    downloadManuscript.toggleClass('hide');
    $(".download_expand_collapse .up_arrow").toggleClass('hide');
    $(".download_expand_collapse .down_arrow").toggleClass('hide');
  }

  markFormUnchanged() {
    window.review.formChanged = false;
  }

  clearTextArea(event) {
    const parentNode = event.target.parentNode.parentNode.children;
    for (let i = 0; i < parentNode.length; i++) {
      $(parentNode[i]).find('.comments').val('').css("height", "");
    }
  }

  toggleOptinNameSection() {
    const nameOptInContainer = $(".name_optIn_container");
    const checkbox = $(".checkbox_for_opt_in");
    if (checkbox.prop('checked')) {
      this.enableSubmitButton();
      nameOptInContainer.removeClass('js-hide');
    } else {
      if ($(".validation_error").length === 0) {
        $("#submit_review_report").attr('disabled', false);
      }
      nameOptInContainer.addClass('js-hide');
    }
  }

  enableSubmitButton() {
    if (!($("#givenName").val().trim().length || $("#familyName").val().trim().length)) {
      $(".name_optIn_container .error_message").removeClass('hidden');
      $("#submit_review_report").attr('disabled', 'disabled');
    } else {
      $(".name_optIn_container .error_message").addClass('hidden');
      if ($(".validation_error").length === 0) {
        $("#submit_review_report").attr('disabled', false);
      }
    }
  }
}
