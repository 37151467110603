import $ from "jquery";
import {Expander} from "@springernature/brand-context";

export class ReviewHeader {
  constructor() {
    this.abstractExpanderTrigger = document.querySelector('[data-component-abstract-expander]');
    this.abstractExpanderTarget = document.querySelector('[data-component-abstract-expander-target]');
    this.calendarExapanderTrigger = document.querySelector('[data-component-calendar-expander]');
    this.calendarExapanderTarget = document.querySelector('[data-component-calendar-expander-target]');
    this.addToCalendarButton = '.c-submission-details-header__add-calendar';
    this.calendarPopup = '.c-submission-details-header__calendar';
    this.downloadFilesButton = '.c-submission-details-header__download-files';
    this.downloadFilesPopup = '.c-submission-details-header__download-files-popup';
    this.readMoreCheckbox = '.read-more__checkbox';
    this.attachEventListners();
  }

  attachEventListners() {
    this.expanderFunction();
    $('body').on("click", this.addToCalendarButton, this.openAndCloseAddToCalendarDropDown.bind(this));
    $('body').on("click", this.downloadFilesButton, this.openAndCloseDownloadFilesDropDown.bind(this));
    $('body').on("click", this.closeOpenPopups.bind(this));
    $('body').on("click", this.readMoreCheckbox, this.toggleAbstractText.bind(this));
  }

  closeOpenPopups(e) {
    this.closeDropDown(e, "c-submission-details-header__add-calendar", this.calendarPopup);
    this.closeDropDown(e, "c-submission-details-header__download-files", this.downloadFilesPopup);
  }

  toggleAbstractText(e) {
    $('.abstract-text').toggleClass('hide');
    $('.abstract-html').toggleClass('js-hide');
  }

  expanderFunction() {
    const abstractExpanderTriggerVar = this.abstractExpanderTrigger;
    const abstractExpanderTargetVar = this.abstractExpanderTarget;
    if (abstractExpanderTriggerVar && abstractExpanderTargetVar) {
      const abstractExpander = new Expander(abstractExpanderTriggerVar, abstractExpanderTargetVar, {CLOSE_ON_FOCUS_OUT: false});
      abstractExpander.init();
    }
  }

  openAndCloseAddToCalendarDropDown(e) {
    e.stopImmediatePropagation();
    this.openAndCloseDropDown(e, "c-submission-details-header__add-calendar", this.calendarPopup);
  }

  openAndCloseDownloadFilesDropDown(e) {
    e.stopImmediatePropagation();
    this.openAndCloseDropDown(e, "c-submission-details-header__download-files", this.downloadFilesPopup);
  }

  openAndCloseDropDown(e, buttonClass, selector) {
    const targetValid = e.currentTarget.classList.contains(buttonClass);
    if (targetValid) {
      if (e.target.classList.contains('is-open')) {
        e.target.classList.remove('is-open');
        $(selector).addClass("u-js-hide");
      } else {
        e.target.classList.add('is-open');
        $(selector).removeClass("u-js-hide");
      }
    } else {
      this.closeDropDown(e, buttonClass, selector);
    }
  }

  closeDropDown(e, buttonClass, selector) {
    const buttonElement = document.getElementsByClassName(buttonClass)[0];
    if (buttonElement && buttonElement.classList.contains('is-open')) {
      buttonElement.classList.remove('is-open');
      $(selector).addClass("u-js-hide");
    }
  }
}
